<template>
    <div class="page-wrapper page-notre-offre">
        <!-- Nav Heading Spacer -->
        <div class="header-spacer"></div>

        <div class="simple-hero-title site-max-width">
            <div class="wrap">
                <h1 class="big-title">{{ content.page.heroSimple.titre }}</h1>
            </div>
        </div>

        <!-- Simple Hero -->
        <section class="simple-hero" v-if="contentIsLoaded">
            <img :src="content.page.heroSimple.image.url" :alt="content.page.heroSimple.image.titre">
        </section>

        <!-- Simple Intro -->
        <section class="intro is-first site-max-width theme-orange" v-if="contentIsLoaded">
            <h2 class="medium-title intro-title" data-inview="fadeInUp" data-delay="400">
                <span v-html="content.page.introSimple1.titre"></span>
            </h2>
            <div class="bottom">
                <p class="text" data-inview="fadeInUp" data-delay="500">
                    <span v-html="content.page.introSimple1.texte"></span>
                </p>
            </div>
        </section>

        <!-- Simple Intro -->
        <section class="intro site-max-width theme-orange" v-if="pageIsLoaded">
            <h2 class="medium-title intro-title" data-inview="fadeInUp" data-delay="400">
                <span v-html="content.page.introSimple2.titre"></span>
            </h2>
            <div class="bottom">
                <p class="text" data-inview="fadeInUp" data-delay="500">
                    <span v-html="content.page.introSimple2.texte"></span>
                </p>
            </div>
        </section>

        <!-- Team -->
        <section class="team" v-if="pageIsLoaded">
            <div class="top">
                <img :src="content.page.equipe.banniere.url" :alt="content.page.equipe.banniere.titre">
            </div>
            <div class="bottom site-max-width">
                <article class="box" parallax-box id="equipe">
                    <div class="left">
                        <img :src="content.page.equipe.image.url" :alt="content.page.equipe.image.titre">
                    </div>
                    <div class="right">
                        <h2 class="medium-title title"><span v-html="content.page.equipe.titre"></span></h2>
                        <p class="regular-text"><span v-html="content.page.equipe.texte"></span></p>
                    </div>
                </article>
                <p class="text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.page.equipe.contenu"></span>
                </p>
            </div>
            <section class="images-scroll">
                <div class="inner" infinite-scroll>
                    <img v-for="(item, i) in content.page.imagesListe" :key="i" :src="item.url" :alt="item.titre">
                    <img v-for="(item, i) in content.page.imagesListe" :key="i" :src="item.url" :alt="item.titre">
                    <img v-for="(item, i) in content.page.imagesListe" :key="i" :src="item.url" :alt="item.titre">
                </div>
            </section>
        </section>

        <!-- Outro -->
        <section class="intro site-max-width theme-rouge outro" v-if="pageIsLoaded">
            <h2 class="medium-title intro-title" data-inview="fadeInUp" data-delay="400">
                <span v-html="content.page.introEtContenu.titre"></span>
            </h2>
            <div class="bottom">
                <p class="text" data-inview="fadeInUp" data-delay="500">
                    <span v-html="content.page.introEtContenu.texte"></span>
                </p>
            </div>
        </section>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'NotreOffre',

    data() {
        return {
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()

                    setTimeout(() => {
                        gsap.utils.toArray('[parallax-box]').forEach(img => {
                            gsap.to(img, {
                                scrollTrigger: {
                                    trigger: img,
                                    start: 'top bottom',
                                    end: 'bottom top',
                                    scrub: 1.475,
                                },
                                y: '-75px',
                                ease: 'none',
                            })
                        })

                        gsap.to('[infinite-scroll]', {
                            duration: 100,
                            delay: 0,
                            x: '-85%',
                            ease: 'linear',
                        })

                        if (this.$route.hash) {
                            gsap.to(window, {duration: 1, scrollTo: this.$route.hash})
                        }
                    }, 1755);
                }
            },
            immediate: true,
        },
    },

    mounted() {},

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
